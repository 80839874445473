<template>
  <div class="content d-flex justify-content-center align-items-center">
    <h1>Bem-vindo!</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
